@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800&family=Shadows+Into+Light&display=swap");
:root {
  --font-family: "Inter", sans-serif;
  --font-sec: "Shadows Into Light", cursive;

  --color-bg: #0e0b16;
  --color-footer: #1c1d1f;

  --color-jewel: #4717f6;
  --color-fushia: #a239ca;
}

.section__padding {
  padding: 5rem 6rem;
}

.section__margin {
  margin: 4rem 6rem;
}

@media screen and (max-width: 700px) {
  .section__padding {
    padding: 4rem;
  }

  .section__margin {
    margin: 4rem;
  }
}

@media screen and (max-width: 550px) {
  .section__padding {
    padding: 4rem 2rem;
  }

  .section__margin {
    margin: 4rem 2rem;
  }
}
