.lax__navbar {
  background: transparent;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 0px;
  padding: 2rem;
  z-index: 999;
}

.active {
  background: var(--color-bg);
  box-shadow: 0 5px 5px -1px;
}

.lax__navbar_logo h1 {
  color: #ffffff;
  font-family: var(--font-family);
  font-size: 25px;
  cursor: pointer;
}

.lax__navbar-links {
  display: none;
}

.lax__navbar-menu {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.lax__navbar-menu__container-links {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 1rem;
  background: var(--color-bg);
}

.lax__navbar-menu__container-links p {
  color: #ffffff;
  font-family: var(--font-family);
  margin: 10px 0;
}

.lax__navbar-menu__container-links button {
  margin-top: 1rem;
  padding: 8px;
  border-radius: 5px;
  color: white;
  background-color: var(--color-fushia);
  border: none;
  font-family: var(--font-family);
  font-weight: 600;
}

@media screen and (min-width: 900px) {
  .lax__navbar-links {
    display: flex;
    width: 60%;
  }
  .lax__navbar-links_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .lax__navbar-links_container p {
    color: #ffffff;
    font-family: var(--font-family);
    margin: 10px 0;
  }

  .lax__navbar-links_container button {
    margin-right: 2rem;
    padding: 8px;
    border-radius: 5px;
    color: white;
    background-color: var(--color-fushia);
    border: none;
    font-family: var(--font-family);
    font-weight: 600;
  }

  .lax__navbar-menu {
    display: none;
  }
}

/* 












.lax__navbar-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.lax__navbar-links_container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.lax__navbar-links_container p {
  color: #ffffff;
  font-family: var(--font-family);
  cursor: pointer;
}

.lax__navbar-links h1 {
  margin-right: 5rem;
}

.lax__navbar-links_container p {
  padding-right: 8rem;
}

.lax__navbar-links_container button {
  padding: 1rem;
  border-radius: 5px;
  color: white;
  background-color: var(--color-fushia);
  border: none;
  font-family: var(--font-family);
  font-weight: 600;
}

.lax__navbar-menu__container-links p {
  color: #ffffff;
  font-family: var(--font-family);
} */
