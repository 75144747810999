.lax__details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.lax__details-main h2 {
  color: #ffffff;
  font-family: var(--font-family);
  text-align: center;
  font-size: 2rem;
  font-weight: 800;
}

.lax__details-main p {
  text-align: center;
  color: #95a2b3;
  font-family: var(--font-family);
  font-size: 1.45rem;
  font-weight: 600;
  margin: 1rem 0;
}

@media screen and (min-width: 900px) {
  .lax__details-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
  }
}
