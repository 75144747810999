.lax__amenityCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #28292c;
  margin: 3rem 0;
  max-width: 500px;
}

.lax__amenityCard-image img {
  width: 100%;
}

.lax__amenityCard-container h2 {
  color: #ffffff;
  font-family: var(--font-family);
  text-align: center;
  padding: 0.5rem 0;
}
.lax__amenityCard-container p {
  color: #8a8f98;
  font-family: var(--font-family);
  text-align: center;
  padding: 0.5rem 1rem 2rem 1rem;
}
