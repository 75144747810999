.lax__ourStory {
  background: #28292c;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.lax__ourStory h2 {
  color: #ffffff;
  font-family: var(--font-family);
  font-size: 2rem;
  font-weight: 800;
  margin-bottom: 1rem;
}

.lax__ourstory-details_content p {
  font-size: 1.1em;
  color: #dadfe7;
  font-family: var(--font-family);
  margin-bottom: 1rem;
  line-height: 30px;
}

.lax__ourstory-details_image {
  max-width: 700px;
}

.lax__ourstory-details_image img {
  width: 100%;
}

@media screen and (min-width: 900px) {
  .lax__ourstory-details {
    display: flex;
    align-items: center;
  }
  .lax__ourstory-details_content {
    max-width: 400px;
    margin-right: 3rem;
  }
}
