.lax__footer {
  background: #28292c;
  padding: 3rem 1rem 1rem 2rem;
}

.lax__footer-top_container {
  margin-bottom: 4rem;
}
.lax__footer-sign p,
.lax__footer-top_container p,
.lax__footer-top_container h2 {
  color: #ffffff;
  font-family: var(--font-family);
  text-align: center;
}

.lax__footer-sign p {
  font-size: 12px;
}

.lax__footer-top_container p {
  max-width: 300px;
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (min-width: 900px) {
  .lax__footer-top {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1400px;
  }
}

/* .lax__footer {
  background: #28292c;
  margin: 0 auto;
  padding: 2rem 0 1rem 0;
}
.lax__footer-top {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.lax__footer-container {
  max-width: 400px;
  padding: 0 2rem;
  margin: 0 0 3rem 0;
}

.lax__footer-container h2 {
  margin-bottom: 5px;
  color: #ffffff;
  font-family: var(--font-family);
  text-align: center;
}

.lax__footer-container p {
  color: #ffffff;
  font-family: var(--font-family);
  text-align: center;
}

.lax__footer-sign p {
  color: #ffffff;
  text-align: center;
}


  .lax__footer-top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3rem;
  }

  .lax__footer-container p {
    text-align: center;
  }

  .lax__footer-container h2 {
    text-align: center;
  }

  .lax__footer {
    background: #28292c;
    display: flex;
  }

  .lax__footer-container {
    max-width: 400px;
    padding: 0;
    margin: 0;
  }
} */
