.lax__amenites {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: 900px) {
  .lax__amenites-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5rem;
  }
}

/* .lax__amenites-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 5rem;
} */
