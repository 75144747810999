.lax__card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #28292c;
  padding: 20px;
  border-radius: 10px;
  max-width: 500px;
  min-height: 200px;
  margin: 2rem;
}

.lax__card h1 {
  color: #ffffff;
  font-family: var(--font-family);
  padding-bottom: 0.5rem;
}
.lax__card p {
  font-size: 1rem;
  color: white;
  font-family: var(--font-family);
  text-align: center;
}
